export function useLocalStorage() {
    function get(key) {
        let content = localStorage.getItem(key);

        try {
            return JSON.parse(content);
        } catch {
            return content;
        }
    }

    function put(key, content) {
        if (typeof content === "undefined") throw new TypeError("You can not put undefined content");

        try {
            content = JSON.stringify(content);
        } catch {
            /* empty */
        }

        localStorage.setItem(key, content);
    }

    function clean(keyName) {
        localStorage.removeItem(keyName);
    }

    function cleanAll() {
        localStorage.clear();
    }

    return {
        get,
        put,
        clean,
        cleanAll,
    };
}
