import axios from "axios";
import { endpoints } from "@data/Routes/Endpoints.js";
import Cookies from "js-cookie";
import { User } from "@/models/UserModel.jsx";
import RequestService from "@/services/RequestService.jsx";

export class AuthService extends RequestService {
    constructor() {
        super();

        // this.csrfCookie = Cookies.get('XSRF-TOKEN')
        // this.isAuthenticated = this.csrfCookie !== undefined;
    }

    /**
     * getCsrfToken checks when there's no XSRF Token cookie and
     * perform a request to update this cookie whenever needed.
     *
     * @returns {Promise<*>}
     */
    getCsrfToken() {
        // if (this.csrfCookie === undefined)
        return axios.get(endpoints.csrf).then(_ => (this.csrfCookie = Cookies.get("XSRF-TOKEN")));
    }

    async login(credentials) {
        await this.getCsrfToken();

        let params = {
            email: credentials.username,
            password: credentials.password,
            remember_me: credentials.rememberMe,
        };

        return axios.post(endpoints.login, params).catch(reason => {
            if (reason.response.status === 403) return;

            throw reason;
        });
    }

    logout() {
        return axios.post(endpoints.logout).catch(reason => {
            if (reason.response.status === 403) return;

            throw reason;
        });
    }

    register(name, email, password, is_creator) {
        let params = {
            name: name,
            email: email,
            password: password,
            is_creator: is_creator,
        };

        return axios.post(endpoints.register, params);
    }

    getUserData() {
        return axios
            .get(endpoints.user)
            .then(response => User.fromJson(response.data))
            .catch(error => {
                throw error;
            });
    }
}

export const getCsrfToken = async () => {
    return new AuthService().getCsrfToken();
};
