import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { CreatorAreaRoutes } from "../data/Routes/CreatorAreaRoutes";

export const useRouteText = () => {
    /**
    * Provide our translation tool to React template
    */
    const { t } = useTranslation();
    /**
    * Hook Responsável por fornecer a rota atual
    */
    const location = useLocation();

    /**
     * Generic function to find routes based on the path prefix.
     * It includes a special treatment for the "/product" prefix, which will look for the route "/product/:productId".
     * 
     * @param {string} prefix - The prefix to check in the current pathname.
     * @returns {string|null} The text of the route if found, otherwise null.
     */
    const findRouteText = (prefix) => {
        if (location.pathname.startsWith(prefix)) {
            // Special case for "/product" to match the dynamic product route "/product/:productId"
            const targetPath = prefix === '/product' ? '/product/:productId' : prefix;
            const route = CreatorAreaRoutes.find(route => route.path === targetPath);
            return route?.text;
        }
        return null;
    };

    /**
     * Checks for specific routes: '/account' and '/product'.
     * Calls the `findRouteText` function to get the corresponding route text for each.
     */
    const accountText = findRouteText('/account');
    const productText = findRouteText('/product');

    /**
     * Finds the current route's text based on the exact pathname in the `CreatorAreaRoutes`.
     * If no specific route matches, it returns the default error message.
     */
    const currentRouteText = CreatorAreaRoutes.find(route => route.path === location.pathname)?.text;

    /**
     * Returns the translated text for the matched route or a default error message if no match is found.
     * 
     * @returns {string} The translated route text.
     */
    return t(accountText || productText || currentRouteText || "titlesHeader.defaultError");
};
