import { lazy } from "react";

/**
 * General routes
 */
const WelcomePlatform = lazy(() => import("@/pages/WelcomePage.jsx"));
const CreateProductPage = lazy(() => import("@/pages/NewProduct/CreateProductPage.jsx"));
const SettingsNotification = lazy(() => import("@/pages/UserManagement/NotificationsSettingsPage.jsx"));
const Reports = lazy(() => import("@/pages/reports.js"));
const Marketing = lazy(() => import("@/pages/marketing.js"));
const Notification = lazy(() => import("@/pages/Notifications/ViewNotificationsPage.jsx"));
const Dashboard = lazy(() => import("@/pages/Dashboard/DashboardPage.js"));
const Sales = lazy(() => import("@/pages/sales.js"));
const FinancesPage = lazy(() => import("@/pages/Finance/FinancesPage.js"));
const ProductsGalleryPage = lazy(() => import("@/pages/ProductsGalleryPage.jsx"));

/**
 * User Profile route groups
 */
const Profile = lazy(() => import("@/pages/UserManagement/Profile/ProfilePage.jsx"));
const AccountPage = lazy(() => import("@/pages/UserManagement/Account/AccountPage.jsx"));
const PersonalData = lazy(() => import("@/pages/UserManagement/PersonalData/PersonalDataPage.jsx"));
const DocumentsPage = lazy(() => import("@/pages/UserManagement/Documents/DocumentsPage.jsx"));
const FinancialDataPage = lazy(() => import("@/pages/UserManagement/FinancialData/FinancialDataPage.jsx"));
const SecurityPage = lazy(() => import("@/pages/UserManagement/Security/SecurityPage.jsx"));

/**
 * Product route groups
 */
const Product = lazy(() => import("@/pages/ProductManagement/MainOutlet/Product.jsx"));
const FilesPage = lazy(() => import("@/pages/ProductManagement/Files/FilesPage.jsx"));
const OffersPage = lazy(() => import("@/pages/ProductManagement/Offers/OffersPage.jsx"));
const CoProductionPage = lazy(() => import("@/pages/ProductManagement/CoProduction/CoProductionPage.jsx"));
const CheckoutSettingsPage = lazy(() => import("@/pages/ProductManagement/CheckoutSettings/CheckoutSettingsPage.jsx"));
const TrackingCodesPage = lazy(() => import("@/pages/ProductManagement/TrackingCodes/TrackingCodesPage.jsx"));
const UpsellDownsellPage = lazy(() => import("@/pages/ProductManagement/UpsellDownsell/UpsellDownsellPage.jsx"));
const CouponsPage = lazy(() => import("@/pages/ProductManagement/Coupons/CouponsPage.jsx"));
const AffiliationPage = lazy(() => import("@/pages/ProductManagement/Affiliation/AffiliationPage.jsx"));
const AreaMembersPage = lazy(() => import("@/pages/AreaMembers/AreaMembersPage.jsx"));
const ConfigurationProductPage = lazy(
    () => import("@/pages/ProductManagement/ConfigurationProduct/ConfigurationProductPage.jsx"),
);
const ConfigurationPage = lazy(() => import("@/pages/UserManagement/ConfigurationPage.jsx"));

export const CreatorAreaRoutes = [
    {
        path: "/welcome",
        element: <WelcomePlatform />,
    },
    {
        path: "/createproduct",
        element: <CreateProductPage />,
    },
    {
        path: "/dashboard",
        element: <Dashboard />,
    },
    {
        path: "/products",
        element: <ProductsGalleryPage />,
        text: "availableMenus.products",
    },
    {
        path: "/sales",
        element: <Sales />,
        text: "availableMenus.sales",
    },
    {
        path: "/financial",
        element: <FinancesPage />,
        text: "availableMenus.financial",
    },
    {
        path: "/reports",
        element: <Reports />,
        text: "availableMenus.reports",
    },
    {
        path: "/marketing",
        element: <Marketing />,
        text: "availableMenus.marketing",
    },
    {
        path: "/profile",
        element: <Profile />,
        text: "titlesHeader.profile",
    },
    {
        path: "/notification",
        element: <Notification />,
        text: "titlesHeader.notifications",
    },
    {
        path: "/configuration",
        element: <ConfigurationPage />,
        text: "Configuração",
    },
    {
        path: "/account",
        element: <AccountPage />,
        text: "titlesHeader.account",
        children: [
            {
                path: "profile",
                element: <Profile />,
            },
            {
                path: "personal-data",
                element: <PersonalData />,
            },
            {
                path: "documents",
                element: <DocumentsPage />,
            },
            {
                path: "financial-data",
                element: <FinancialDataPage />,
            },
            {
                path: "security",
                element: <SecurityPage />,
            },
            {
                path: "notification-settings",
                element: <SettingsNotification />,
            },
        ],
    },
    {
        path: "/product/:productId",
        element: <Product />,
        text: "titlesHeader.product",
        children: [
            {
                index: true,
                element: <ConfigurationProductPage />,
            },
            {
                path: "files",
                element: <FilesPage />,
                text: "Arquivos",
            },
            {
                path: "offers",
                element: <OffersPage />,
                text: "Ofertas",
            },
            {
                path: "checkout-settings",
                element: <CheckoutSettingsPage />,
                text: "Checkout configurações",
            },
            {
                path: "tracking-codes",
                element: <TrackingCodesPage />,
                text: "Códigos de rastreamento",
            },
            {
                path: "upsell-downsell",
                element: <UpsellDownsellPage />,
                text: "upsell-downsell",
            },
            {
                path: "coupons",
                element: <CouponsPage />,
                text: "Cupom",
            },
            {
                path: "affiliation",
                element: <AffiliationPage />,
                text: "Afiliados",
            },
            {
                path: "area-members",
                element: <AreaMembersPage />,
                text: "Area de membros",
            },
            {
                path: "co-production",
                element: <CoProductionPage />,
                text: "Co-produtor",
            },
        ],
    },
];
